let prefix = '/api/sap/';
let prefixExp = '/api/sap/purchase-orders-report/';

function getReportPaymentGeneral(fechaIni,fechaFin,tipo,elemento,currency,report){
    return window.axios.get(prefix+'reports/showPaymentPurchase/'+fechaIni+'/'+fechaFin+'/'+tipo+'/'+elemento+'/'+currency+'/'+report);
}
function getReportAssignmentGeneral(fechaIni,fechaFin,tipo,elemento,currency,report){
    return window.axios.get(prefix+'reports/showAssignmentPurchase/'+fechaIni+'/'+fechaFin+'/'+tipo+'/'+elemento+'/'+currency+'/'+report);
}
function getReportPurchaseGeneral(fechaIni,fechaFin,tipo,elemento,currency,report){
    return window.axios.get(prefix+'reports/showGeneralPurchase/'+fechaIni+'/'+fechaFin+'/'+tipo+'/'+elemento+'/'+currency+'/'+report);
}
function getProjects(data){
    return window.axios.get(prefix+'projects/index',data);
}
function getArticles(data){
    return window.axios.get(prefix+'items/index',data);
}
function getEmployees(data){
    return window.axios.get(prefix+'salesemployee/index',data);
}
function getSupplier(data){
    return window.axios.get(prefix+'business-partners/show/S',data);
}
function getGroup(data){
    return window.axios.get(prefix+'item-groups/GroupsWarehouse/index',data);
}
function exportPDF(data){
    return window.axios.post(prefixExp+'exportPDF',data,{responseType: 'blob'});
}
function exportExcel(data){
    return window.axios.post(prefixExp+'exportExcel',data,{responseType: 'blob'});
}
export default {
    getProjects,getArticles,getEmployees,getSupplier,getGroup,exportPDF,exportExcel,
    getReportPaymentGeneral,getReportAssignmentGeneral,getReportPurchaseGeneral

}