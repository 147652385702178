const namespaced = true
import api from '../../../../api/sap/report/report_purchase.js';
import { downloadFile } from '../../../../src/helpers/util.js'
const state = {
    report_sap_assig: [],
    report_sap_general: [],
    report_sap_payment: [],
    itemProjects:[],
    itemArticles:[],
    itemSupplier:[],
    itemGroup:[],
    itemEmployee:[],
    selectedRows:[],
    loading:false,
}
const mutations = {
    MUTATION_SELECTED(state, data){
        state.selectedRows = data;
    },
    MUTATION_SET_REPORT_SAP_ASSIG(state, data){
         state.report_sap_assig = data;
    },
    MUTATION_SET_REPORT_SAP_GENERAL(state, data){
        state.report_sap_general = data;
   },
   MUTATION_SET_REPORT_SAP_PAYMENT(state, data){
    state.report_sap_payment = data;
},
    MUTATION_SET_PROJECTS_SAP(state, data){
        state.itemProjects = data;
   },
    MUTATION_SET_ARTICLES_SAP(state, data){
    state.itemArticles = data;
    },
    MUTATION_SET_EMPLOYEE_SAP(state, data){
        state.itemEmployee = data;
        },
    MUTATION_SET_SUPPLIER_SAP(state, data){
    state.itemSupplier = data;
    },
    MUTATION_SET_GROUP_SAP(state, data){
        state.itemGroup = data;
    },
    SET_LOADING(state, val) {
        state.loading = val;
    },
}
const actions = {
    getReportAssignmentGeneral(context, data) {
        context.commit('SET_LOADING', true);
        api.getReportAssignmentGeneral(data.fechaIni,data.fechaFin,data.tipo,data.elemento,data.currency,data.report).then(function (res) {
            context.commit('MUTATION_SET_REPORT_SAP_ASSIG', res.data);
            context.commit('SET_LOADING', false);
        }).catch((e) => {

            context.commit('SET_LOADING', false);
            context.commit('MUTATION_SET_REPORT_SAP_ASSIG',[]);
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getReportPaymentGeneral(context, data) {
        context.commit('SET_LOADING', true);
        api.getReportPaymentGeneral(data.fechaIni,data.fechaFin,data.tipo,data.elemento,data.currency,data.report).then(function (res) {
            context.commit('SET_LOADING', false);
            context.commit('MUTATION_SET_REPORT_SAP_PAYMENT', res.data);
        }).catch((e) => {
     
            context.commit('SET_LOADING', false);
            context.commit('MUTATION_SET_REPORT_SAP_PAYMENT',[]);
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getReportPurchaseGeneral(context, data) {
        context.commit('SET_LOADING', true);
        api.getReportPurchaseGeneral(data.fechaIni,data.fechaFin,data.tipo,data.elemento,data.currency,data.report).then(function (res) {
            context.commit('SET_LOADING', false);
            context.commit('MUTATION_SET_REPORT_SAP_GENERAL',  res.data);
        }).catch((e) => {
        
            context.commit('SET_LOADING', false);
            context.commit('MUTATION_SET_REPORT_SAP_GENERAL',[]);
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getProjects(context){
        api.getProjects().then(function (res) {
            context.commit('MUTATION_SET_PROJECTS_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getArticles(context){
        api.getArticles().then(function (res) {
            context.commit('MUTATION_SET_ARTICLES_SAP',  res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getEmployees(context){
        api.getEmployees().then(function (res) {
            context.commit('MUTATION_SET_EMPLOYEE_SAP',  res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },


    getSupplier(context){
        api.getSupplier().then(function (res) {
            context.commit('MUTATION_SET_SUPPLIER_SAP', res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    getGroup(context){
        api.getGroup().then(function (res) {
            context.commit('MUTATION_SET_GROUP_SAP',  res.data);
        }).catch((e) => {
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    exportPDF(context,data){
        var val = new Date( new Date(data['fechaFin']) -  new Date(data['fechaIni']));
        var restaDias =Math.round(val/ (1000*60*60*24));
        if(restaDias > 31){
          context.dispatch("notifications/add", {'color' : 'error', 'visibility' : true,'timeout' : 3000, 'text' : 'La decarga del Archivo PDF solamente se permite a partir de 1 mes'}, { root: true })
          return;
        }
        context.commit('SET_LOADING', true);
        api.exportPDF(data).then(function (res) {
            context.commit('SET_LOADING', false);
            const url = window.URL.createObjectURL(new Blob([ res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Reporte de Compras.pdf');
            document.body.appendChild(link);
            link.click();

        }).catch((e) => {
            context.commit('SET_LOADING', false);
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': e.messages}, {root: true });
        })
    },
    exportExcel(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.exportExcel(data.data).then(function (res) {
                downloadFile(res.data, data.name);
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },

    getRowsAssigment(context, data) {
        context.commit('MUTATION_SELECTED',[]);
        let array = [];
        if (data.selected.length != data.item.length && data.selected[0]['fila'] != 'FOR_INSTANCE') {
            for (var j in data.selected) {
            if (data.selected[j]['fila'] != 'FOR_INSTANCE') {
                array.push({
                    "0":data.selected[j]['fecha_de_requisicion'],
                    "1":data.selected[j]['fecha_de_oc'],
                    "2":data.selected[j]['fecha_de_factura'],
                    "3":data.selected[j]['planta'],
                    "4":data.selected[j]['requisicion'],
                    "5":data.selected[j]['oc'],
                    "6":data.selected[j]['factura'],
                    "7":data.selected[j]['no_de_entrada_de_almacen'],
                    "8":data.selected[j]['fecha_de_entrada_de_almacen'],
                    "9":data.selected[j]['proveedor'],
                    "10":data.selected[j]['familia'],
                    "11":data.selected[j]['producto'],
                    "12":data.selected[j]['cantidad'],
                    "13":data.selected[j]['unidad_de_medida'],
                    "14":data.selected[j]['costounitario'],
                    "15":data.selected[j]['subTotal'],
                    "16":data.selected[j]['iva'],
                    "17":data.selected[j]['total'],
                    "18":data.selected[j]['moneda'],
                });
            }
        }
        }else{
            for (var index in data.item) {
            array.push({
                "0":data.item[index]['fecha_de_requisicion'],
                "1":data.item[index]['fecha_de_oc'],
                "2":data.item[index]['fecha_de_factura'],
                "3":data.item[index]['planta'],
                "4":data.item[index]['requisicion'],
                "5":data.item[index]['oc'],
                "6":data.item[index]['factura'],
                "7":data.item[index]['no_de_entrada_de_almacen'],
                "8":data.item[index]['fecha_de_entrada_de_almacen'],
                "9":data.item[index]['proveedor'],
                "10":data.item[index]['familia'],
                "11":data.item[index]['producto'],
                "12":data.item[index]['cantidad'],
                "13":data.item[index]['unidad_de_medida'],
                "14":data.item[index]['costounitario'],
                "15":data.item[index]['subTotal'],
                "16":data.item[index]['iva'],
                "17":data.item[index]['total'],
                "18":data.item[index]['moneda'],
            });
            }
        }
           context.commit('MUTATION_SELECTED',array);
    },
    getRowsGeneral(context, data) {
        context.commit('MUTATION_SELECTED',[]);
        let array = [];
        if (data.selected.length != data.item.length && data.selected[0]['fila'] != 'FOR_INSTANCE') {
            for (var j in data.selected) {
            if (data.selected[j]['fila'] != 'FOR_INSTANCE') {
                array.push({
                    "0":data.selected[j]['fecha_de_requisicion'],
                    "1":data.selected[j]['fecha_de_oc'],
                    "2":data.selected[j]['fecha_de_factura'],
                    "3":data.selected[j]['comprador'],
                    "4":data.selected[j]['planta'],
                    "5":data.selected[j]['requisicion'],
                    "6":data.selected[j]['oc'],
                    "7":data.selected[j]['factura'],
                    "8":data.selected[j]['proveedor'],
                    "9":data.selected[j]['familia'],
                    "10":data.selected[j]['producto'],
                    "11":data.selected[j]['cantidad'],
                    "12":data.selected[j]['unidad_de_medida'],
                    "13":data.selected[j]['costounitario'],
                    "14":data.selected[j]['subTotal'],
                    "15":data.selected[j]['iva'],
                    "16":data.selected[j]['total'],
                    "17":data.selected[j]['moneda'],
                });
            }
        }
        }else{
            for (var index in data.item) {
            array.push({
                "0":data.item[index]['fecha_de_requisicion'],
                "1":data.item[index]['fecha_de_oc'],
                "2":data.item[index]['fecha_de_factura'],
                "3":data.item[index]['comprador'],
                "4":data.item[index]['planta'],
                "5":data.item[index]['requisicion'],
                "6":data.item[index]['oc'],
                "7":data.item[index]['factura'],
                "8":data.item[index]['proveedor'],
                "9":data.item[index]['familia'],
                "10":data.item[index]['producto'],
                "11":data.item[index]['cantidad'],
                "12":data.item[index]['unidad_de_medida'],
                "13":data.item[index]['costounitario'],
                "14":data.item[index]['subTotal'],
                "15":data.item[index]['iva'],
                "16":data.item[index]['total'],
                "17":data.item[index]['moneda'],
            });
            }
        }
           context.commit('MUTATION_SELECTED',array);
    },
    getRowsPayment(context, data) {
        context.commit('MUTATION_SELECTED',[]);
        let array = [];
        if (data.selected.length != data.item.length && data.selected[0]['fila'] != 'FOR_INSTANCE') {
            for (var j in data.selected) {
            if (data.selected[j]['fila'] != 'FOR_INSTANCE') {
                array.push({
                     "0":data.selected[j]['fecha_de_requisicion'],
                     "1":data.selected[j]['fecha_de_oc'],
                     "2":data.selected[j]['fecha_de_factura'],
                     "3":data.selected[j]['fecha_de_vencimiento'],
                     "4":data.selected[j]['dias_de_credito'],
                     "5":data.selected[j]['dias_vencidos'],
                     "6":data.selected[j]['pagado'],
                     "7":data.selected[j]['fecha_de_pago'],
                     "8":data.selected[j]['planta'],
                     "9":data.selected[j]['requisicion'],
                     "10":data.selected[j]['oc'],
                     "11":data.selected[j]['factura'],
                     "12":data.selected[j]['proveedor'],
                     "13":data.selected[j]['familia'],
                     "14":data.selected[j]['producto'],
                     "15":data.selected[j]['cantidad'],
                     "16":data.selected[j]['unidad_de_medida'],
                     "17":data.selected[j]['costounitario'],
                     "18":data.selected[j]['subTotal'],
                     "19":data.selected[j]['iva'],
                     "20":data.selected[j]['total'],
                     "21":data.selected[j]['moneda'],
                });
            }
        }
        }else{
            for (var index in data.item) {
            array.push({
                "0":data.item[index]['fecha_de_requisicion'],
                "1":data.item[index]['fecha_de_oc'],
                "2":data.item[index]['fecha_de_factura'],
                "3":data.item[index]['fecha_de_vencimiento'],
                "4":data.item[index]['dias_de_credito'],
                "5":data.item[index]['dias_vencidos'],
                "6":data.item[index]['pagado'],
                "7":data.item[index]['fecha_de_pago'],
                "8":data.item[index]['planta'],
                "9":data.item[index]['requisicion'],
                "10":data.item[index]['oc'],
                "11":data.item[index]['factura'],
                "12":data.item[index]['proveedor'],
                "13":data.item[index]['familia'],
                "14":data.item[index]['producto'],
                "15":data.item[index]['cantidad'],
                "16":data.item[index]['unidad_de_medida'],
                "17":data.item[index]['costounitario'],
                "18":data.item[index]['subTotal'],
                "19":data.item[index]['iva'],
                "20":data.item[index]['total'],
                "21":data.item[index]['moneda'],
            });
            }
        }
           context.commit('MUTATION_SELECTED',array);
    },
    sendMessage(context,data){
        if(data.type == 'success'){
            context.dispatch('notifications/add',{'color': 'success', 'visibility' : true, 'timeout': 5000, 'text': data.messages}, {root: true });
        }else{
            context.dispatch('notifications/add',{'color': 'error', 'visibility' : true, 'timeout': 5000, 'text': data.messages}, {root: true });
        }

    }
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}